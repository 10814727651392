/*--------------------------------------------------------------
  Tabs
--------------------------------------------------------------*/

.tabs {
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  border-bottom: 1px solid $iron;

  @include clearfix();
}


.tab {
  float: left;

  &.is-active {
    a {
      color: $charcoal;
      border-color: $primary;
    }
  }

  a {
    font-size: 12px;
    font-weight: $font-weight-bold;
    display: block;
    padding: 1em .75em;
    text-transform: uppercase;
    color: $base;
    border-bottom: 4px solid transparent;

    &:hover {
      color: $charcoal;
    }
  }
}
