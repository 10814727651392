/*--------------------------------------------------------------
  A to B
--------------------------------------------------------------*/

.a-to-b {
  font-size: 18px;
  font-weight: $font-weight-bold;
  line-height: 1.5;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  color: $oil;

  li {
    position: relative;
    padding-left: ($spacing-unit * 1.5);

    &:first-child {
      padding-bottom: 11px;
      background-position: 7px 27px;
      background-size: 2px 100px;

      @extend %dots-bgr;

      .shape {
        fill: $primary;
      }
    }
  }

  .shape {
    position: absolute;
    top: 3px;
    width: 16px;
    height: 22px;
    margin-left: -($spacing-unit * 1.5);
    fill: $base;
  }

  &.a-to-b--small {
    font-size: 16px;

    li {
      padding-left: $spacing-unit;

      &:first-child {
        padding-bottom: 6px;
        background-position: 5px 22px;
      }

      &:last-child {
        background-position: 5px -95px;
        background-size: 2px 100px;

        @extend %dots-bgr;

      }

      .shape {
        top: 2px;
        width: 12px;
      }
    }

    .shape {
      margin-left: -$spacing-unit;
    }
  }
}

%dots-bgr {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAADICAYAAADP20CxAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTYzMTk5OTRCNDUxMTFFNDg3M0VDMTYxQTNCRTYyOTAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTYzMTk5OTVCNDUxMTFFNDg3M0VDMTYxQTNCRTYyOTAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3RkYxRUJGRkI0NEQxMUU0ODczRUMxNjFBM0JFNjI5MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo3RkYxRUMwMEI0NEQxMUU0ODczRUMxNjFBM0JFNjI5MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgHCes0AAABdSURBVHjaYlm1apUnAwPDXAYISGaBciShAnOZGFABI0ggBYhfAPFTkBbG////oyhB10KOAMvq1atHHTYahKMOG3XYaBCOaIeNBtBorhwNwlGHjeaG0SAcrA4DCDAAx1nh2l2rZoQAAAAASUVORK5CYII=');
  background-repeat: no-repeat;
}
