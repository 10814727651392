/*--------------------------------------------------------------
  Layout
--------------------------------------------------------------*/

$nav-width: 180px;

.site {
  display: flex;
  min-height: 100vh;
  min-width: 1260px;
}

.site__header {
  display: flex;
  flex: 0 0 $nav-width;
  flex-direction: column;
  min-height: 100vh;
  background-color: $oil;
}

/**
 *  Selector hack for Firefox.
    http://browserhacks.com/#hack-fcd70847fc9af76ec582d13fe50066c8

    Firefox does not respect `flex: 0 0 180px` and thus needs the width in
    explicit pixels. Safari on the other hand cannot
    handle this at all due to old webkit while Chrome does as the spec says.

    So easiest solution: target Firefox and fix this issue.
 */
_:-moz-tree-row(hover), .site__header {
  width: $nav-width;
  flex: none;
}

.site__main {
  flex: 1 100%;
  background-color: #f5f5f5;
}

/**
 * Header
 */

.site__branding {
  display: flex;
  justify-content: center;
  padding: $spacing-unit;
  background-color: $primary;

  .shape {
    width: 60px;
    height: 60px;
    fill: $white;
  }
}


.site__profile {
  background-color: #444;
  padding: $spacing-unit;

  .shape {
    fill: $white;
  }

  a {
    color: $white;
    font-weight: $font-weight-bold;

    &:hover,
    &:active,
    &:focus {
      color: $white;
    }
  }
}

// Profile drop menu

.drop-content-profile {
  display: none;
}

.drop.drop--profile {
    top: 12px !important;
    width: 160px;

    &:before {
        position: absolute;
        right: 5px;
        display: block;
        width: 0;
        height: 0;
        margin-top: -16px;
        content: '';
        border-width: 12px;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: $white;
    }

    .drop-content {
        padding: 10px 20px;
        border: 0;

        a {
            font-weight: $font-weight-bold;
            line-height: 1.8;
            display: block;
            color: $oil;

            &:hover,
            &:active,
            &:focus {
                color: $charcoal;
            }
        }
    }
}


.site__legal {
  font-size: 12px;
  padding: $spacing-unit;
  text-align: center;

  a,
  a:hover,
  a:active,
  a:focus {
    color: $aluminum;
  }
}

/**
 * Navigation
 */

.navigation {
  flex-grow: 1;

  ul {
    width: $nav-width;
  }
}

.navigation--primary {
  padding: $spacing-unit;

  .shape {
    width: 24px;
    height: 24px;
    margin-right: ($spacing-unit / 2);
    fill: $charcoal;
  }

  ul {
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
  }

  li {
    margin-bottom: $spacing-unit;
  }

  a {
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;
    color: $base;

    &.is-active,
    &:hover,
    &:active,
    &:focus {
      color: $iron;

      .shape {
        fill: $iron;
      }
    }
  }
}

.navigation--secondary {
  flex: 0 0 $nav-width;
  padding: $spacing-unit;
  border-right: 1px solid $border-color--light;

  ul {
    margin-bottom: 2em;

    @extend .list-bare;
  }

  li {
    font-weight: $font-weight-bold;
    line-height: 2;
    text-transform: uppercase;
    color: $oil;

    a {
      color: $primary;
      display: block;
      text-transform: none;

      &:hover,
      &:active,
      &:focus {
        color: $primary--dark;
      }

      &.is-active {
        color: $charcoal;
      }
    }
  }
}

/**
 * Main
 */

.main-header {
  min-height: 102px;
  padding: $spacing-unit;
  border-bottom: 1px solid $border-color--light;
  background-color: $white;
}

.main-content {
  padding: $spacing-unit;
}
