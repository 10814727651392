/*--------------------------------------------------------------
  Badge
--------------------------------------------------------------*/

.badge {
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  text-align: center;
  vertical-align: top;
  white-space: nowrap;
  color: $white;
  border-radius: 12px;
  background-color: $primary;
  h1 &,
  h2 & {
    font-size: 50%;
  }
}

.badge:empty {
  display: none;
}
