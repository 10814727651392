/*--------------------------------------------------------------
  Switch
--------------------------------------------------------------*/

.switch {
  input {
    position: absolute;
    visibility: hidden;
    margin-left: -9999px;
    + label,
    + input + label {
      position: relative;
      display: block;
      width: 36px;
      height: 18px;
      padding: 2px;
      cursor: pointer;
      user-select: none;
      border-radius: 18px;
      outline: none;
      background-color: $iron;
      &:before,
      &:after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 1px;
        display: block;
        content: '';
      }
      &:before {
        right: 1px;
        transition: all $transition-speed;
        border-radius: 18px;
      }
      &:after {
        width: 16px;
        transition: all $transition-speed;
        border-radius: 100%;
        background-color: #fff;
        box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
      }
    }
    &:checked + label,
    &:checked + input + label {
      background-color: $primary;
      &:before {
        background-color: $primary;
      }
      &:after {
        margin-left: 18px;
      }
    }
  }
}
