/*--------------------------------------------------------------
  Tooltip
--------------------------------------------------------------*/

.tooltip-element {
    position: absolute;
    display: none;
    box-sizing: border-box;
    transition: opacity 150ms;
    opacity: 0;
    &:after {
        box-sizing: border-box;
    }
    &:before {
        box-sizing: border-box;
    }
    * {
        box-sizing: border-box;
        &:after {
            box-sizing: border-box;
        }
        &:before {
            box-sizing: border-box;
        }
    }
    &.tooltip-open {
        display: block;
    }
    &.tooltip-after-open {
        opacity: 1;
    }
    &.tooltip-theme-arrows {
        max-width: 100%;
        max-height: 100%;
        pointer-events: none;
        .tooltip-content {
            font-family: inherit;
            line-height: 1.5em;
            position: relative;
            padding: 1em;
            padding: 0.5em 1em;
            color: $white;
            border-radius: $radius * 2;
            background: $oil;
            &:before {
                position: absolute;
                display: block;
                width: 0;
                height: 0;
                content: '';
                border-width: 6px;
                border-style: solid;
                border-color: transparent;
            }
        }
        &.tooltip-element-attached-bottom {
            &.tooltip-element-attached-left {
                &.tooltip-target-attached-top .tooltip-content {
                    margin-bottom: 6px;
                    &:before {
                        top: 100%;
                        left: 6px;
                        border-top-color: $oil;
                    }
                }
                &.tooltip-target-attached-right .tooltip-content {
                    margin-left: 6px;
                    &:before {
                        right: 100%;
                        bottom: 6px;
                        border-right-color: $oil;
                    }
                }
            }
            &.tooltip-element-attached-right {
                &.tooltip-target-attached-top .tooltip-content {
                    margin-bottom: 6px;
                    &:before {
                        top: 100%;
                        right: 6px;
                        border-top-color: $oil;
                    }
                }
                &.tooltip-target-attached-left .tooltip-content {
                    margin-right: 6px;
                    &:before {
                        bottom: 6px;
                        left: 100%;
                        border-left-color: $oil;
                    }
                }
            }
            &.tooltip-element-attached-center .tooltip-content {
                margin-bottom: 6px;
                &:before {
                    top: 100%;
                    left: 50%;
                    margin-left: -6px;
                    border-top-color: $oil;
                }
            }
        }
        &.tooltip-element-attached-top {
            &.tooltip-element-attached-left {
                &.tooltip-target-attached-bottom .tooltip-content {
                    margin-top: 6px;
                    &:before {
                        bottom: 100%;
                        left: 6px;
                        border-bottom-color: $oil;
                    }
                }
                &.tooltip-target-attached-right .tooltip-content {
                    margin-left: 6px;
                    &:before {
                        top: 6px;
                        right: 100%;
                        border-right-color: $oil;
                    }
                }
            }
            &.tooltip-element-attached-right {
                &.tooltip-target-attached-bottom .tooltip-content {
                    margin-top: 6px;
                    &:before {
                        right: 6px;
                        bottom: 100%;
                        border-bottom-color: $oil;
                    }
                }
                &.tooltip-target-attached-left .tooltip-content {
                    margin-right: 6px;
                    &:before {
                        top: 6px;
                        left: 100%;
                        border-left-color: $oil;
                    }
                }
            }
            &.tooltip-element-attached-center .tooltip-content {
                margin-top: 6px;
                &:before {
                    bottom: 100%;
                    left: 50%;
                    margin-left: -6px;
                    border-bottom-color: $oil;
                }
            }
        }
        &.tooltip-element-attached-right.tooltip-element-attached-middle .tooltip-content {
            margin-right: 6px;
            &:before {
                top: 50%;
                left: 100%;
                margin-top: -6px;
                border-left-color: $oil;
            }
        }
        &.tooltip-element-attached-left.tooltip-element-attached-middle .tooltip-content {
            margin-left: 6px;
            &:before {
                top: 50%;
                right: 100%;
                margin-top: -6px;
                border-right-color: $oil;
            }
        }
    }
}
