/*--------------------------------------------------------------
  Icons
--------------------------------------------------------------*/

/**
 * 1. Match icon size with text size as default
 * 2. Double the size
 */

.shape {
  display: inline-block;
  width: $font-size; /* 1 */
  height: $font-size; /* 1 */
  vertical-align: text-bottom;
  fill: $black;

  &--large {
    width: ($font-size * 1.5); /* 2 */
    height: ($font-size * 1.5); /* 2 */
  }

  &--larger {
    width: ($font-size * 2); /* 2 */
    height: ($font-size * 2); /* 2 */
  }

  &.disabled {
    fill: $base;

    &:hover {
      fill: $base;
    }
  }
}
