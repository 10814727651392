/*--------------------------------------------------------------
  Drop
--------------------------------------------------------------*/

.drop-element {
  position: absolute;
  display: none;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  &:after {
    box-sizing: border-box;
  }
  &:before {
    box-sizing: border-box;
  }
  * {
    box-sizing: border-box;
    &:after {
      box-sizing: border-box;
    }
    &:before {
      box-sizing: border-box;
    }
  }
  &.drop-open {
    display: block;
  }
  .drop-content {
    font-family: inherit;
    padding: ($spacing-unit / 2) 24px;
    color: $charcoal;
    border: 1px solid $iron;
    border-radius: $radius;
    background-color: $white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .2);
    ul {
      margin-bottom: 0;
    }
    .list-block {
      margin: -($spacing-unit / 2) -24px;
      a {
        display: block;
        padding: ($spacing-unit / 4) 24px;
        color: $charcoal;
        &:hover {
          color: $oil;
        }
      }
    }
  }
}
.drop-target {
  &:hover {
    cursor: pointer;
  }
  & + .drop-content {
    display: none;
  }
}
.drop-target-attached-top {
  .drop-content {
    margin-bottom: 1px;
  }
}
.drop-target-attached-right {
  .drop-content {
    margin-left: 1px;
  }
}
.drop-target-attached-bottom {
  .drop-content {
    margin-top: 1px;
  }
}.drop-target-attached-left {
  .drop-content {
    margin-right: 1px;
  }
}
