.popover {
  font-family: $font-stack;
  font-size: $font-size;
  position: absolute;
  z-index: 1060;
  top: 0;
  left: 0;
  display: none;
  max-width: 276px;
  text-align: left;
  white-space: normal;
  border: 1px solid $iron;
  border-radius: $radius;
  background-color: $white;
  background-clip: padding-box;
  box-shadow: 0 5px 25px rgba(18,16,11,.23);
  @extend %clearfix;
}
.popover-content {
  @extend %clearfix;
  ul {
    margin-bottom: 0;
    padding-left: 0;
  }
}
.popover.top {
  margin-top: -10px;
}
.popover.right {
  margin-left: 10px;
}
.popover.bottom {
  margin-top: 10px;
}
.popover.left {
  margin-left: -10px;
}
.popover-title {
  font-size: 14px;
  margin: 0;
  padding: 8px 14px;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
  background-color: #f7f7f7;
}

.popover-content > ul {
  margin-left: 0;
  list-style-type: none;
}
.popover-content > ul > li {
  border-bottom: 1px solid $iron;
  &:last-child {
    border-bottom: none;
  }
}
.popover-content > ul > li > a {
  display: block;
  padding: ($spacing-unit / 2) $spacing-unit;
  color: $charcoal;
  &:hover {
    color: $black;
    background-color: $primary--light;
  }
}
.popover > .arrow,
.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
}
.popover > .arrow {
  border-width: 11px;
}
.popover > .arrow:after {
  content: '';
  border-width: 10px;
}
.popover.top > .arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: $white;
  border-bottom-width: 0;
}
.popover.top > .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: ' ';
  border-top-color: $white;
  border-bottom-width: 0;
}
.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: $white;
  border-left-width: 0;
}
.popover.right > .arrow:after {
  bottom: -10px;
  left: 1px;
  content: ' ';
  border-right-color: $white;
  border-left-width: 0;
}
.popover.bottom > .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: $iron;
}
.popover.bottom > .arrow:after {
  top: 1px;
  margin-left: -10px;
  content: ' ';
  border-top-width: 0;
  border-bottom-color: $white;
}
.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: $white;
}
.popover.left > .arrow:after {
  right: 1px;
  bottom: -10px;
  content: ' ';
  border-right-width: 0;
  border-left-color: $white;
}
