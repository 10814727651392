/*--------------------------------------------------------------
  Button
--------------------------------------------------------------*/

.button {
    font-family: $font-stack;
    font-size: $font-size;
    font-weight: bold;
    line-height: inherit;
    line-height: 1.42857143;
    position: relative;
    display: inline-block;
    height: 36px;
    margin-bottom: 0;
    padding: 6px 24px;
    user-select: none;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none;
    color: $white;
    border: 1px solid $primary--dark;
    border-radius: $radius;
    background-color: $primary;
    background-image: linear-gradient($primary, shade($primary, 8%));

    touch-action: manipulation;
    &:hover {
        cursor: pointer;
        color: $white;
        background-color: tint($primary, 10%);
        background-image: linear-gradient(tint($primary, 10%), $primary);
    }
    &:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: 0 0 1px 0 #fff, 0 0 7px 2px rgba(55,188,186,1);
    }
    &.is-active,
    &:active {
        background-image: linear-gradient(shade($primary, 8%), $primary);
        box-shadow: none;
    }
    &:disabled {
        color: $base;
        border: 1px solid $iron;
        background-color: tint($iron, 50%);
        background-image: none !important;
        &:hover {
            cursor: not-allowed;
        }
    }
    .shape {
        margin-bottom: 2px;
        fill: $white;
    }
    &.ascdesc {
        padding-right: $spacing-unit * 2;
    }
    &.asc:after,
    &.desc:after {
        position: absolute;
        right: $spacing-unit;
        content: '';
        border: 4px solid;
        border-color: transparent;
    }
    &.asc:after {
        top: 15px;
        border-top-color: $iron;
    }
    &.desc:after {
        top: 12px;
        border-bottom-color: $iron;
    }
}

/**
 * Colors variants
 */

.button--hollow {
    color: $charcoal;
    border-color: $iron;
    background-color: $white;
    background-image: linear-gradient($white, shade($white, 5%));
    &.is-active {
        color: $oil;
        background-color: $white;
        background-image: none;
    }
    &:hover {
        color: $charcoal;
        background-color: $white;
        background-image: none;
    }
    &:focus {
        border-color: $primary;
        box-shadow: 0 0 8px rgba(55,188,186,0.75);
    }
    &.is-active,
    &:active {
        background-image: linear-gradient(shade($white, 5%), $white);
    }
    .shape {
        fill: $charcoal;
    }
}

.button--success {
    border-color: shade($success, 10%);
    background-color: $success;
    background-image: linear-gradient($success, shade($success, 5%));
    &:hover {
        background-color: tint($success, 10%);
        background-image: linear-gradient(tint($success, 10%), $success);
    }
    &:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: 0 0 1px 0 $white, 0 0 7px 2px $success;
    }
    &.is-active,
    &:active {
        background-image: linear-gradient(shade($success, 5%), $success);
        box-shadow: none;
    }
    &:disabled {
        color: $white;
    }
    .shape {
        fill: $charcoal;
    }
}

.button--danger {
    border-color: shade($danger, 10%);
    background-color: $danger;
    background-image: linear-gradient($danger, shade($danger, 5%));
    &:hover {
        background-color: tint($danger, 15%);
        background-image: linear-gradient(tint($danger, 15%), $danger);
    }
    &:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: 0 0 1px 0 $white, 0 0 7px 2px $danger;
    }
    &.is-active,
    &:active {
        background-image: linear-gradient(shade($danger, 5%), $danger);
        box-shadow: none;
    }
    &:disabled {
        color: $white;
    }
    .shape {
        fill: $charcoal;
    }
}

.button--warning {
    color: $oil;
    border-color: shade($warning, 10%);
    background-color: $warning;
    background-image: linear-gradient($warning, shade($warning, 5%));
    &:hover {
        color: $oil;
        background-color: tint($warning, 15%);
        background-image: linear-gradient(tint($warning, 15%), $warning);
    }
    &:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: 0 0 1px 0 $white, 0 0 7px 2px $warning;
    }
    &.is-active,
    &:active {
        background-image: linear-gradient(shade($warning, 5%), $warning);
        box-shadow: none;
    }
    .shape {
        fill: $oil;
    }
}

/**
 * Sizes
 */

.button--block {
    width: 100%;
}

// Vertically space out multiple block buttons
.button--block + .button--block {
    margin-top: 5px;
}

.button--small {
    font-size: 12px;
    line-height: 20px;
    height: 22px;
    padding: 0 8px;
}

.button--narrow {
    margin-top: 1px;
    padding: 7px 5px 2px 5px;
    background-color: transparent;
    .shape {
        width: 18px;
        height: 18px;
        transition: $transition-speed $transition-easing;
        transition-delay: 0.3s;
    }
    &.is-active {
        background-color: $white;
        .shape {
            transform: rotate(180deg);
        }
    }
}

.button-group {
    display: flex;
    & > .button {
        z-index: 1;
        margin-left: -1px;
        border-radius: 0;
        &:focus {
            z-index: 2;
        }
        &:first-child {
            margin-left: 0;
            border-top-left-radius: $radius;
            border-bottom-left-radius: $radius;
        }
        &:last-child {
            border-top-right-radius: $radius;
            border-bottom-right-radius: $radius;
        }
        &.drop-target {
            line-height: 8px;
            height: 36px;
            margin-left: -4px;
            padding: 10px;
        }
    }
}
.button-group--block {
    display: flex;
    .button {
        flex: 1;
        float: none;
    }
}
